import { request } from "@/utils/service"
import { RoleInfo } from "@/models/types/RoleInfo"
import { jsDateFormatToJsonDate } from "@/utils/dateFormate"
const api_name = "/system/user"

const api_auth_name = "/system/auth"
export function logoutApi(token: string) {
  const r = request<ApiResponseData<boolean>>({
    url: `${api_auth_name}/logout`,
    method: "post",
    params: {
      token: token
    }
  })
  return r
}

/*
获取后台用户分页列表(带搜索)
*/
export function getPageList(page, limit, searchObj) {
  return request({
    // url: `${api_name}/${page}/${limit}`,
    url: `${api_name}/getUserListByName`,
    method: "get",
    params: {
      pageNum: page,
      pageSize: limit,
      user_name: searchObj.username,
      is_valid: true
    }
  })
}

/**
 *
 * @param {integer}page 页码
 * @param {integer}limit 页大小
 * @param {Object} searchObj 查询条件
 * @returns {*}
 */
export function getUserListByNameForCheck(page, limit, searchObj) {
  return request({
    // url: `${api_name}/${page}/${limit}`,
    url: `${api_name}/getUserListByNameForCheck`,
    method: "get",
    params: {
      pageNum: page,
      pageSize: limit,
      deptID: searchObj.deptID,
      user_name: searchObj.username,
      is_valid: true
    }
  })
}

/*
根据ID获取某个后台用户
*/
export function getById(id) {
  return request({
    url: `${api_name}/get/${id}`,
    method: "get"
  })
}

/*
保存一个新的后台用户
*/
export function add(user) {
  return request({
    url: `${api_name}/addUser`,
    method: "post",
    data: user
  })
}

/*
更新一个后台用户
*/
export function update(user) {
  return request({
    url: `${api_name}/updateUser`,
    method: "post",
    data: user
  })
}

/*
更新一个后台用户
*/
export function updateUserPWD(userID, pwd) {
  return request({
    url: `${api_name}/updateUserPWD`,
    method: "post",
    params: {
      userID: userID,
      pwd: pwd
    }
  })
}

/**
 * 修改用户名
 * @param userID 用户id
 * @param userName 用户名
 */
export function updateUserName(userID, userName) {
  return request({
    url: `${api_name}/updateUserName`,
    method: "post",
    params: {
      userID: userID,
      userName: userName
    }
  })
}

/*
获取某个用户的所有角色
*/
export function getRoles(userId) {
  return request<ApiResponseData<RoleInfo[]>>({
    url: `${api_name}/getRolesForUser`,
    method: "post",
    params: {
      userID: userId
    }
  })
}

/*
获取后台用户分页列表(带搜索)
*/
export function getUserListByRole(roleID) {
  return request({
    // url: `${api_name}/${page}/${limit}`,
    url: `${api_name}/getUserListByRoleID`,
    method: "post",
    params: {
      roleID: roleID
    }
  })
}

/*
给某个用户分配角色
roleId的结构: 字符串, 'rId1,rId2,rId3'
*/
export function assignRoles(userId, roleIds) {
  return request({
    url: `${api_name}/saveRolesForUser`,
    method: "post",
    params: {
      userId
    },
    data: roleIds
  })
}

/*
删除某个用户
*/
export function removeById(id) {
  return request({
    url: `${api_name}/remove`,
    method: "delete",
    params: {
      userid: id
    }
  })
}

/*
批量删除多个用户
ids的结构: ids是包含n个id的数组
*/
export function removeUsers(ids) {
  return request({
    url: `${api_name}/batchRemove`,
    method: "delete",
    data: ids
  })
}

/*
上传照片
*/
export function uploadAvatar(userID, formData) {
  return request({
    url: `${api_name}/uploadAvatar`,
    method: "post",
    params: {
      userID: userID
    },
    data: formData,
    headers: { "content-type": "multipart/form-data" }
    //headers: { 'content-type': 'multipart/form-data' }
  })
}

/**
 * 检测用户是否有某些角色配置权限
 * @param userID
 * @param optionKey
 * @returns {*}
 */
export function checkUserRoleAuth(userID, optionKey) {
  return request({
    url: `${api_name}/checkUserRoleAuth`,
    method: "post",
    params: {
      userID: userID,
      optionKey: optionKey
    }
  })
}

/**通过部门查询用户列表 */
export function getUserListByDept(deptId) {
  return request({
    url: `${api_name}/getUserListByDept`,
    method: "post",
    params: {
      deptId: deptId
    }
  })
}

export function getFacePhotoByUserOID(userID, imageguid = "", w = "", h = "") {
  return request({
    url: `${api_name}/getFacePhotoByUserOID`,
    method: "post",
    params: {
      imageguid: "",
      userOID: userID,
      w: w,
      h: h
    }
  })
}

export function getUserFacePhotoByUserOID(userID, imageguid = "", w = "", h = "") {
  return request({
    url: `${api_name}/getUserFacePhotoByUserOID`,
    method: "get",
    params: {
      imageguid: "",
      userOID: userID,
      w: w,
      h: h
    }
  })
}

/**获取用户信息 */
export function getUserByUserID(userid) {
  return request({
    url: `${api_name}/getUserByUserID`,
    method: "post",
    params: {
      userid: userid
    }
  })
}

export function getUserListByRoleIDAndGroupID(roleID, groupID, isHolder) {
  return request({
    url: `${api_name}/getUserListByRoleIDAndGroupID`,
    method: "post",
    params: {
      roleID: roleID,
      groupID: groupID,
      isHolder: isHolder
    }
  })
}

/**
 * 获取用户列表(用于获取上级用户)
 * @param page
 * @param limit
 */
export function getUserListForParentUser(page, limit) {
  return request({
    url: `${api_name}/getUserListForDropDown`,
    method: "post",
    data: {
      isPage: true,
      pageNum: page,
      pageSize: limit,
      condition: null
    }
  })
}

/**
 * 保存直接上级
 * @param managerInfo
 */
export function saveManagerInfo(managerInfo) {
  return request({
    url: `${api_name}/saveManagerInfo`,
    method: "post",
    data: managerInfo
  })
}

/**
 * 获取DM用户
 * @param userID
 */
export function getUserListForProjectLeader(userID) {
  return request({
    url: `${api_name}/getUserListForProjectLeader`,
    method: "post",
    params: {
      userID: userID
    }
  })
}
